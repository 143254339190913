.lro-thumbnail {
  height: 1.5rem;
  width: 150px;
}

.lro-thumbnail img {
  transform-origin: top right;
  max-width: 1.5rem;
  margin-left: -1.5rem;
  max-height: 150px;
  transform: rotate(-90deg);
}